<template>
    <div class="column-group">
        <div class="row mx-0 align-items-center border-bottom py-3">
            <i class="icon-left f-12 ml-2 text-general cr-pointer" @click="geocercasCarpetas" />
            <div class="col-auto px-2 f-18 text-general f-600">
                {{ geocercaCarpeta.nombre }}
            </div>
            <el-popover placement="bottom" effect="light" class="ml-auto mr-2" popper-class="p-2 br-8">
                <div class="row mx-0">
                    <div class="col-12 item cr-pointer py-1" @click="editarGrupo">
                        Editar
                    </div>
                    <div class="col-12 item cr-pointer py-1" @click="eliminarGrupo">
                        Eliminar
                    </div>
                </div>
                <div slot="reference" class="btn-action border">
                    <i v-if="$can('gestionar_geocercas')" class="icon-dots-vertical f-20" />
                </div>
            </el-popover>
        </div>
        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 230px)">
            <div v-for="(data,key) in geocercas" :key="key" class="row mx-0 justify-center">
                <div class="col-11 border br-8 py-2 mb-2 cr-pointer">
                    <div class="row mx-0">
                        <p class="f-16 f-600 text-general">
                            {{ data.nombre }}
                        </p>
                        <el-popover placement="bottom" effect="light" class="ml-auto" popper-class="p-2 br-8">
                            <div class="row mx-0">
                                <div class="col-12 item cr-pointer py-1" @click="openEditarGeocerca(data)">
                                    Editar
                                </div>
                                <div class="col-12 item cr-pointer py-1" @click="openEliminarGeocerca(data)">
                                    Eliminar
                                </div>
                            </div>
                            <div slot="reference" class="btn-action border">
                                <i v-if="$can('gestionar_geocercas')" class="icon-dots-vertical f-20" />
                            </div>
                        </el-popover>
                    </div>

                    <el-tooltip placement="bottom" :content="data.descripcion" effect="light">
                        <div class="row mx-0 align-items-center mt-2">
                            <i class="icon-leechero-cap text-general" />
                            <div class="col-auto pl-1 f-16 text-general">
                                {{ data.cant_vendedores }}  {{ $config.vendedor }}
                            </div>
                            <div class="col-auto pl-1 f-16 text-general">
                                En <b>{{ data.cant_grupos }}</b> grupos de {{ $config.vendedor }}
                            </div>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div v-if="geocercas.length == 0" class="row mx-0 justify-center mt-4">
                <div class="col-10 text-center text-general mb-2">
                    Aun no se han creado <br /> geocercas
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalEditarGeocerca" titulo="Editar Geocerca" no-aceptar adicional="Actualizar" @adicional="editarGeocerca">
            <div class="row mx-0 justify-center">
                <div class="col-10">
                    <small class="pl-3 text-general">
                        Nombre la geocerca
                    </small>
                    <el-input v-model="geocercaActiva.nombre" placeholder="Nombre" maxlength="60" show-word-limit size="small" class="w-100" />
                </div>
                <div class="col-10 mt-3">
                    <small class="pl-3 text-general">
                        Descripción
                    </small>
                    <el-input v-model="geocercaActiva.descripcion" placeholder="Descripción" maxlength="300" show-word-limit type="textarea" :rows="5" class="w-100" size="small" />
                </div>
            </div>
        </modal>
        <modal ref="modalEditarGrupo" titulo="Editar grupo de geocerca" no-aceptar adicional="Guardar" @adicional="putGeocercaCarpeta">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-10">
                        <small class="pl-3 text-general">
                            Nombre la geocerca
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre">
                            <el-input v-model="geocercaCarpeta.nombre" placeholder="Nombre" maxlength="60" show-word-limit size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-10 mt-3">
                        <small class="pl-3 text-general">
                            Descripción
                        </small>
                        <el-input v-model="geocercaCarpeta.descripcion" placeholder="Descripción" maxlength="350" show-word-limit type="textarea" :rows="5" class="w-100" size="small" />
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modalEliminar ref="modalEliminarGrupo" titulo="Eliminar grupo de geocerca" mensaje="¿Desea eliminar este grupo?" @eliminar="deleteGeocercaCarpeta" />
        <modalEliminar ref="modalEliminarGeocerca" titulo="Eliminar Geocerca" mensaje="¿Desea eliminar la Geocerca?" @eliminar="eliminarGeocerca" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Geocerca from '~/services/geocerca/geocerca'
import GeocercasPuntos from '~/services/geocerca/geocercasPuntos'
export default {
    data(){
        return {
            nombre: '',
            descripcion: '',
            geocercaCarpeta: {},

            geocercaActiva:{}
        }
    },
    computed:{
        ...mapGetters({
            geocercas:'geocercas/geocercas/geocercas'
        }),
        idGrupo(){
            return this.$route.params.idGrupo;
        }
    },
    mounted(){
        this.verGeocercaCarpeta()
        this.getGeocercas()
    },
    methods: {
        editarGrupo(){
            this.$refs.modalEditarGrupo.toggle();
        },
        eliminarGrupo(){
            this.$refs.modalEliminarGrupo.toggle();
        },
        geocercasCarpetas(){
            this.$router.push({name: 'admin.geocercas.grupos'})
        },
        async verGeocercaCarpeta(){
            try {
                const params = {
                    idGrupo : this.idGrupo
                }
                const {data} = await Geocerca.verGeocercaCarpeta(params)
                this.geocercaCarpeta = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async putGeocercaCarpeta(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                const payload = {
                    nombre: this.geocercaCarpeta.nombre,
                    descripcion: this.geocercaCarpeta.descripcion
                }
                await Geocerca.putGeocercaCarpeta(this.idGrupo, payload)

                this.notificacion('','Grupo geocerca editada correctamente','success')
                this.$refs.modalEditarGrupo.toggle();
                this.verGeocercaCarpeta();
            } catch (e){
                this.error_catch(e)
            }
        },
        async deleteGeocercaCarpeta(){
            try {
                await Geocerca.deleteGeocercaCarpeta(this.idGrupo)

                this.notificacion('','Grupo geocerca eliminado correctamente','success')
                this.$refs.modalEliminarGrupo.toggle();
                this.$router.push({name: 'admin.geocercas.grupos'})
            } catch (e){
                this.error_catch(e)
            }
        },
        async getGeocercas(){
            try {
                const params = {
                    idGrupo : this.idGrupo
                }
                const {data} = await Geocerca.getGeocercas(params)
                this.$store.commit('geocercas/geocercas/setGeocercas', data.data)
                this.$emit('loadGeocercas')
            } catch (e){
                this.error_catch(e)
            }
        },
        openEditarGeocerca(geocerca){
            this.geocercaActiva = geocerca
            this.$refs.modalEditarGeocerca.toggle()
        },
        async editarGeocerca(){
            try {
                const form = {
                    id : this.geocercaActiva.id,
                    nombre:this.geocercaActiva.nombre,
                    descripcion:this.geocercaActiva.descripcion
                }
                const {data} = await GeocercasPuntos.updateGeocerca(form)
                this.notificacion('','Datos actualizados correctamente','success')
                this.$refs.modalEditarGeocerca.toggle()
                this.geocercaActiva = {}

            } catch (e){
                this.error_catch(e)
            }
        },
        openEliminarGeocerca(geocerca){
            this.geocercaActiva = geocerca
            this.$refs.modalEliminarGeocerca.toggle()
        },
        async eliminarGeocerca(){
            try {
                const idGeocerca = this.geocercaActiva.id

                const {data} = await GeocercasPuntos.deleteGeocerca(idGeocerca)
                this.notificacion('','Geocerca Eliminada','success')
                this.$store.commit('geocercas/geocercas/removeGeocerca', idGeocerca)
                this.$refs.modalEliminarGeocerca.toggle()
                this.$emit('reload')
                this.geocercaActiva = {}


            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.column-group{
    width: 380px;
    background-color: #FFFFFF;
    border-radius: 8px 0px 0px 0px;
}
.item:hover{
    background-color: #F6F9FB;
}
</style>
